/*                                 Apache License
                           Version 2.0, January 2004
                        http://www.apache.org/licenses/
Software in the Sustain Ecosystem are Released Under Terms of Apache Software License 
This research has been supported by funding from the US National Science Foundation's CSSI program through awards 1931363, 1931324, 1931335, and 1931283. The project is a joint effort involving Colorado State University, Arizona State University, the University of California-Irvine, and the University of Maryland - Baltimore County. All redistributions of the software must also include this information. 
TERMS AND CONDITIONS FOR USE, REPRODUCTION, AND DISTRIBUTION
1. Definitions.
"License" shall mean the terms and conditions for use, reproduction, and distribution as defined by Sections 1 through 9 of this document.
"Licensor" shall mean the copyright owner or entity authorized by the copyright owner that is granting the License.
"Legal Entity" shall mean the union of the acting entity and all other entities that control, are controlled by, or are under common control with that entity. For the purposes of this definition, "control" means (i) the power, direct or indirect, to cause the direction or management of such entity, whether by contract or otherwise, or (ii) ownership of fifty percent (50%) or more of the outstanding shares, or (iii) beneficial ownership of such entity.
"You" (or "Your") shall mean an individual or Legal Entity exercising permissions granted by this License.
"Source" form shall mean the preferred form for making modifications, including but not limited to software source code, documentation source, and configuration files.
"Object" form shall mean any form resulting from mechanical transformation or translation of a Source form, including but not limited to compiled object code, generated documentation, and conversions to other media types.
"Work" shall mean the work of authorship, whether in Source or Object form, made available under the License, as indicated by a copyright notice that is included in or attached to the work (an example is provided in the Appendix below).
"Derivative Works" shall mean any work, whether in Source or Object form, that is based on (or derived from) the Work and for which the editorial revisions, annotations, elaborations, or other modifications represent, as a whole, an original work of authorship. For the purposes of this License, Derivative Works shall not include works that remain separable from, or merely link (or bind by name) to the interfaces of, the Work and Derivative Works thereof.
"Contribution" shall mean any work of authorship, including the original version of the Work and any modifications or additions to that Work or Derivative Works thereof, that is intentionally submitted to Licensor for inclusion in the Work by the copyright owner or by an individual or Legal Entity authorized to submit on behalf of the copyright owner. For the purposes of this definition, "submitted" means any form of electronic, verbal, or written communication sent to the Licensor or its representatives, including but not limited to communication on electronic mailing lists, source code control systems, and issue tracking systems that are managed by, or on behalf of, the Licensor for the purpose of discussing and improving the Work, but excluding communication that is conspicuously marked or otherwise designated in writing by the copyright owner as "Not a Contribution."
"Contributor" shall mean Licensor and any individual or Legal Entity on behalf of whom a Contribution has been received by Licensor and subsequently incorporated within the Work.
2. Grant of Copyright License. Subject to the terms and conditions of this License, each Contributor hereby grants to You a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable copyright license to reproduce, prepare Derivative Works of, publicly display, publicly perform, sublicense, and distribute the Work and such Derivative Works in Source or Object form.
3. Grant of Patent License. Subject to the terms and conditions of this License, each Contributor hereby grants to You a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable (except as stated in this section) patent license to make, have made, use, offer to sell, sell, import, and otherwise transfer the Work, where such license applies only to those patent claims licensable by such Contributor that are necessarily infringed by their Contribution(s) alone or by combination of their Contribution(s) with the Work to which such Contribution(s) was submitted. If You institute patent litigation against any entity (including a cross-claim or counterclaim in a lawsuit) alleging that the Work or a Contribution incorporated within the Work constitutes direct or contributory patent infringement, then any patent licenses granted to You under this License for that Work shall terminate as of the date such litigation is filed.
4. Redistribution. You may reproduce and distribute copies of the Work or Derivative Works thereof in any medium, with or without modifications, and in Source or Object form, provided that You meet the following conditions:
You must give any other recipients of the Work or Derivative Works a copy of this License; and
You must cause any modified files to carry prominent notices stating that You changed the files; and
You must retain, in the Source form of any Derivative Works that You distribute, all copyright, patent, trademark, and attribution notices from the Source form of the Work, excluding those notices that do not pertain to any part of the Derivative Works; and
If the Work includes a "NOTICE" text file as part of its distribution, then any Derivative Works that You distribute must include a readable copy of the attribution notices contained within such NOTICE file, excluding those notices that do not pertain to any part of the Derivative Works, in at least one of the following places: within a NOTICE text file distributed as part of the Derivative Works; within the Source form or documentation, if provided along with the Derivative Works; or, within a display generated by the Derivative Works, if and wherever such third-party notices normally appear. The contents of the NOTICE file are for informational purposes only and do not modify the License. You may add Your own attribution notices within Derivative Works that You distribute, alongside or as an addendum to the NOTICE text from the Work, provided that such additional attribution notices cannot be construed as modifying the License. 
You may add Your own copyright statement to Your modifications and may provide additional or different license terms and conditions for use, reproduction, or distribution of Your modifications, or for any such Derivative Works as a whole, provided Your use, reproduction, and distribution of the Work otherwise complies with the conditions stated in this License.
5. Submission of Contributions. Unless You explicitly state otherwise, any Contribution intentionally submitted for inclusion in the Work by You to the Licensor shall be under the terms and conditions of this License, without any additional terms or conditions. Notwithstanding the above, nothing herein shall supersede or modify the terms of any separate license agreement you may have executed with Licensor regarding such Contributions.
6. Trademarks. This License does not grant permission to use the trade names, trademarks, service marks, or product names of the Licensor, except as required for reasonable and customary use in describing the origin of the Work and reproducing the content of the NOTICE file.
7. Disclaimer of Warranty. Unless required by applicable law or agreed to in writing, Licensor provides the Work (and each Contributor provides its Contributions) on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied, including, without limitation, any warranties or conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE. You are solely responsible for determining the appropriateness of using or redistributing the Work and assume any risks associated with Your exercise of permissions under this License.
8. Limitation of Liability. In no event and under no legal theory, whether in tort (including negligence), contract, or otherwise, unless required by applicable law (such as deliberate and grossly negligent acts) or agreed to in writing, shall any Contributor be liable to You for damages, including any direct, indirect, special, incidental, or consequential damages of any character arising as a result of this License or out of the use or inability to use the Work (including but not limited to damages for loss of goodwill, work stoppage, computer failure or malfunction, or any and all other commercial damages or losses), even if such Contributor has been advised of the possibility of such damages.
9. Accepting Warranty or Additional Liability. While redistributing the Work or Derivative Works thereof, You may choose to offer, and charge a fee for, acceptance of support, warranty, indemnity, or other liability obligations and/or rights consistent with this License. However, in accepting such obligations, You may act only on Your own behalf and on Your sole responsibility, not on behalf of any other Contributor, and only if You agree to indemnify, defend, and hold each Contributor harmless for any liability incurred by, or claims asserted against, such Contributor by reason of your accepting any such warranty or additional liability. 
END OF TERMS AND CONDITIONS
*/

import * as React from 'react';
import { Stack, Paper, Divider } from '@mui/material';

export default function About() {
    
    return(
        <Paper>
            <Stack>
                <article style={{paddingTop:"2.5%"}}>
                        <header>
                            <h2 className="centered-text">About</h2>
                            <p className="centered-text">Towards Deeper, Faster Voluminous Spatiotemporal Data Explorations</p>
                        </header>

                        <div style={{paddingLeft:'7.5%', paddingRight:'7.5%'}}>
                            <p>The Sustain project is a collaboration between Colorado State University, Arizona State University, University of California — Irvine, and the University of Maryland - Baltimore County.</p>
                            <p>The project includes an advisory board that includes representation from academia, industry, and citizen science.</p>

                            
                            <p><strong>The United States is highly urbanized with more than 80% of the population residing in cities.</strong> Cities draw from and impact natural resources and ecosystems while utilizing vast, expensive infrastructures to meet economic, social, and environmental needs. The NSF has invested in several strategic <strong>research efforts in the area of urban sustainability</strong>, all of which generate, collect, and manage large volumes of spatiotemporal data.</p>
                            <p>Voluminous datasets are also made available by governmental agencies and NGOs in domains such as climate, ecology, health, and census. These data can spur exploration of new questions and hypotheses, particularly across traditionally disparate disciplines, and offer unprecedented opportunities for discovery and innovation. <strong>However, the data are encoded in diverse formats and managed using a multiplicity of data management frameworks — all contributing to a Balkanization of the observational space that inhibits discovery.</strong> A scientist must reconcile not only the encoding and storage frameworks, but also negotiate authorizations to access the data.</p>
                            <p>A consequence is that data are locked in institutional silos, each of which represents only a sliver of the observational space. <strong>This project, SUSTAIN (Software for Urban Sustainability to Tailor Analyses over Interconnected Networks), facilitates and accelerates discovery by targeting the alleviation of data-induced inefficiencies.</strong></p>
                            <ul style={{paddingLeft:'10%'}}>
                                <li>Federating data across diverse administrative domains.</li>
                                <li>Data encoded in diverse formats such as netCDF, BUFR, HDF 4/5, CSV, etc.</li>
                                <li>Data stored using diverse storage frameworks such as file systems, relational databases, NoSQL systems, and document stores.</li>
                                <li>Imputations of missing data at diverse spatiotemporal scopes.</li>
                                <li>Visualization of voluminous spatiotemporal datasets</li>
                                <li>Interoperation with commercial spatial analyses software such as ESRI's ArcGIS and Google Earth Engine.</li>
                            </ul>
                            <p>© The Sustain Project<br/>
                            <i>This research has been supported by funding from the US National Science Foundation's CSSI program through awards 1931363, 1931324, 1931335, and 1931283. The project is a joint effort involving Colorado State University, Arizona State University, the University of California-Irvine, and the University of Maryland - Baltimore County.</i></p>
                            < Divider sx={{paddingTop:'3%'}} />
                        </div>
                    </article>
                    
                    <article style={{marginBottom:'2.5%', paddingTop:'-1%'}}>
                        <header>
                            <h2 className="centered-text">Offerings</h2>
                        </header>
                        <div style={{paddingLeft:'7.5%', paddingRight:'7.5%'}}>
                            <p>The Sustain Project consists of several modules working in tandem to allow our users to establish an end-to-end understanding of Urban Data.</p>
                            <p>The Sustain project includes support for a rich set of services that are aligned with the needs of the urban sustainability community. We engage with two of the three major urban sustainability networks in the United States: UWIN and UReX. Broadly services within Sustain include the following:</p>
                            <ul style={{paddingLeft:'10%'}}>
                                <li>Data federation</li>
                                <li>Visualization</li>
                                <li>Query specification including support for query predicate formulation</li>
                                <li>Model validation</li>
                                <li>Model building</li>
                            </ul>
                            <p>Data Federation: Our data federation schemes reconcile data induced challenges: heterogeneity and volumes and storage mechanisms. All operators work across contiguous, disparate, or overlapping spatiotemporal scales. Additionally, a fluent interface allows chaining operators to formulate complex analyses subsets of data. To ensure timeliness, we manage the speed differential of the memory hierarchy, disperse loads and avoid I/O hotspots, preserve data locality during processing, and avoid disk and CPU contention.</p>
                            <p>Visualization: A key feature we support is overlay of datasets. This involves fusion of datasets based on spatial and chronological attributes. An example of such an operation is to overlay topographical information such as roads or natural boundaries on observed phenomena - disease clusters, for instance. We provide support for feature class datasets (including ESRI shapefile format), such as city block polygons, roads, power lines, and rivers. Overlays will also be used to contrast regions at different points in time.</p>
                            <p>Queries: Our queries allow a user to constrain the geographical area of interest and rank results based on proximity. The query geometry may be specified as a feature class, including points, lines, quadratic and cubic (Bezier) curves, and polygons. Our proximity queries will allow location-based ranking and search of data of interest. Queries begin at an anchor point, and incrementally increasing annuli (donut shaped geometry) radiate outward till a certain number of results are available. For example, researchers will be able to identify environmentally sensitive areas or water supply systems adjacent to a contaminated site or chemical spill. Another important application of these queries is assessing the risk to assets due to sea level rise, hurricanes or flooding.</p>
                            <p>Model validation and assessments will be launched by treating the models as black boxes; models may be packaged either as application containers (e.g., Docker, RKT) or as a virtual appliance. Model assessments will be queued and scheduled as background jobs with low priority. Each model assessment workload will be encapsulated within a single VM (by default). The sizing and prioritization of the pool of VMs will be based on load to minimize interference with interactive explorations of the observational spaces.</p>
                            <p>Model building: The ingestion process refers to reconciling data formats, performing spatiotemporal data alignments, addressing issues of scale via sketching, and indexing the data so that it is amenable for querying, visualization, and visualization. Notably, we can perform fusion of datasets based on spatial and chronological attributes. An example of such an operation is to overlay topographical information such as roads or natural boundaries on observed phenomena - disease clusters, for instance. We provide support for feature class datasets (including ESRI shapefile format), such as city block polygons, roads, power lines, and rivers. Overlays will also be used to contrast regions at different points in time. We have currently ingested, or are in the process of ingesting, several spatiotemporal datasets relating to urban systems. These datasets are in different formats (CSV, Tabbed formats, GeoTIFF, JPEG, JSON, GeoJSON, GRIB, XML, ESRI geo-databases, ESRI shapefiles) from NGOs and state/federal agencies. Furthermore, each of the datasets includes a large number of features and can be available at different granularities.</p>
                        </div>
                    </article>
            </Stack>
        </Paper>
    );
}