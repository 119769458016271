import getEnvoyUrl from "./getEnvoyUrl";

export default class Api {
    static serverUrl = `${getEnvoyUrl()}/sustain_site/api`;

    static async sendRequest(requestBody, requestType) {
        const fetchOptions = {
            method: 'POST',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(`${Api.serverUrl}/${requestType}`, fetchOptions);
            if (response.ok) {
                return response.json();
            } else {
                console.error(`Request to server ${Api.serverUrl} failed: ${response.status}: ${response.statusText}`);
            }
        } catch (err) {
            console.error(`Request to server failed : ${err}`);
        }

        return null;
    }
}
