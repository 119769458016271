import { Divider, Paper, Stack } from "@mui/material";
import rasterformats from '../../json/rasterformats.json'
import vectorformats from '../../json/vectorformats.json'
import FileTable, { SupportedFormatsHead, SupportedFormatsRow } from "./FileTable";

/* 25,760 raster file conversions, 6,972 vector file conversions */

export default function SupportedFormats() {
    return (
        <Paper>
            <Stack sx={{paddingBottom: '50px'}}>
                <header style={{margin: 'auto', padding: '2%'}}>
                    <h2> Supported Data Formats </h2>
                </header>
                <p  style={{maxWidth: '75%', margin: 'auto'}}>
                    Through our tool, Data Distiller , we can perform over 25,000 different conversions between 161 different raster file formats, {' '}
                    and almost 7,000 conversions between 84 different vector file formats. We also support nearly all <Link href='https://epsg.org/home.html' text='EPSG' /> {' '}
                    and all <Link href='https://geodesy.noaa.gov/datums/horizontal/north-american-datum-1983.shtml' text='NAD' /> spatial and coordinate referencing systems.
                    Listed below are some of the notable file formats we support.
                </p>
                <Divider sx={{width: '75%', margin: 'auto', padding: '20px'}} />
                <Stack style={{marginLeft: '12.5%', paddingTop: '30px'}} spacing={4}>
                    {tables.map((table) => {
                        table.array.sort((a, b) => a.name < b.name ? -1 : 1)
                        return (
                            <FileTable table={table} key={table.title} />
                        )
                    })}
                </Stack>
            </Stack>
        </Paper>
    )
}

const tables = [
    {
        title: 'Notable Raster Formats',
        head: SupportedFormatsHead,
        row: SupportedFormatsRow,
        array: rasterformats
    },
    {
        title: 'Notable Vector Formats',
        head: SupportedFormatsHead,
        row: SupportedFormatsRow,
        array: vectorformats
    },
    // {
    //     title: 'List of Spatial Referencing Systems',
    //     head: SystemsHead,
    //     row: SystemsRow,
    //     array: spatialSystems
    // },
    // {
    //     title: 'List of Coordinate Referencing Systems',
    //     head: SystemsHead,
    //     row: SystemsRow,
    //     array: coordinateSystems
    // }
] 

function Link({href, text}) {
    return <a href={href} target='_blank' rel='noreferrer'>{text}</a>
}
