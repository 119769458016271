import { useState } from 'react'


export default function useMap(manageableMap = new Map()) {
	const [map, setMap] = useState(manageableMap)

	const context = {
		map,
		setMap
	}

	return {
		map,
		size: map.size,
		has: (key) => has(key, context),
		get: (key) => get(key, context),

		set: (key, value) => set(key, value, context),
		insertEntries: (entries) => insertEntries(entries, context),
		modify: (key, modifyFunc) => modify(key, modifyFunc, context),

		delete: (key) => deleteElement(key, context),
		deleteMultiple: (keys) => deleteMultiple(keys, context),

		valuesArray: () => valuesArray(context),
		entriesArray: () => entriesArray(context)
	}
}

function has(key, context) {
	return context.map.has(key)
}

function get(key, context) {
	return context.map.get(key)
}

function set(key, value, context) {
	context.setMap((prevMap) => new Map(prevMap.set(key, value)))
}

function insertEntries(entries, context) {
	context.setMap((prevMap) => {
		const newMap = new Map(entries)
		return new Map([...prevMap, ...newMap]) // Merges the maps
	})
}

function modify(key, modifyFunc, context) {
	const currentValue = get(key, context)
	if (currentValue) set(key, modifyFunc(currentValue), context)
}

// delete is a reserved word
function deleteElement(key, context) {
	deleteMultiple([key], context)
}

function deleteMultiple(keys, context) {
	context.setMap((prevMap) => {
		keys.forEach((key) => prevMap.delete(key))
		return new Map(prevMap)
	})
}

function valuesArray(context) {
	return Array.from(context.map.values())
}

function entriesArray(context) {
	return Array.from(context.map.entries())
}
