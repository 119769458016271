import { useRef, useState } from 'react';
import useAccountActions from '../useAccountActions';
import isEmail from 'validator/lib/isEmail';

const useLogin = ({ handleClose }) => {
    const accountActions = useAccountActions();

    const [alertText, setAlertText] = useState('');
    const [password, setPassword] = useState('');
    /*-----------------email validation------------------*/
    const [validEmail, setValidEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const handleEmailChange = (text) => {
        if (text.target.value === '') {
            setValidEmail(false);
            setEmailError(false);
            setValidEmail(text.target.value);
            return;
        }
        setEmail(text.target.value);
        setValidEmail(isEmail(text.target.value));
        setEmailError(!isEmail(text.target.value));
    };
    /*---------------------------------------------------*/

    /*-----------------submit handling------------------*/
    const loginButtonRef = useRef(null);

    const onEnter = (event) => {
        if (event.code === 'Enter') {
            loginButtonRef.current.click();
        }
    };

    const loginUser = async (event, rememberMe) => {
        event.preventDefault();
        const { exists, success } = await accountActions.loginUser({ email, password, rememberMe });
        if (!exists) {
            setAlertText('No user with this email exists');
        } else if (!success) {
            setAlertText('Password incorrect');
        } else {
            handleClose();
        }
    };
    /*---------------------------------------------------*/

    return {
        emailError,
        handleEmailChange,
        password,
        setPassword,
        alertText,
        validEmail,
        loginButtonRef,
        onEnter,
        loginUser,
        rememberMe: accountActions.rememberMe,
        setRememberMe: accountActions.setRememberMe
    };
};
export default useLogin;
