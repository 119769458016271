import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import getEnvoyUrl from "../../getEnvoyUrl";

export const SustainSessionContext = createContext(null);

export function SustainSessionProvider({children}) {

    const sustainSessionContextHook = useSustainSessionContext()

    return (
        <SustainSessionContext.Provider value={sustainSessionContextHook}>
            {children}
        </SustainSessionContext.Provider>
    )

}

const defaultUser = { 
    id: -1,
    name: "",
    email: "",
}

function useSustainSessionContext() {

    const [user, setUser] = useState(defaultUser);

    useEffect(() => {
        void sendStartSession(setUser)
    }, [])

    return {
        user,
        setUser,
        startNewSession: () => sendStartSession(setUser),
        updateUser: () => fetchUser(setUser)
    }

}

const base_url = `${getEnvoyUrl()}/sustain_site/api`

async function sendStartSession(setUser) {
    await fetch(`${base_url}/startSession`, {
            method: 'POST', 
            credentials: 'include' // Include cookies
    })
    fetchUser(setUser)
}

async function fetchUser(setUser) {
    const response = await fetch(`${base_url}/getUserInfo`, {
        method: 'POST', 
        credentials: 'include', // Include cookies
    })
    const body = await response.json()
    setUser(body);
}


export default function useSustainSession() {

    const sustainSession = useContext(SustainSessionContext);

    const [cookies, setCookie, removeCookie] = useCookies(['session'])

    const context = {
        sustainSession,
        cookies,
        setCookie, 
        removeCookie
    }

    return {
        user: sustainSession.user,
        getSessionId: () => getSessionId(context),
        logout: () => logout(context),
        updateUser: sustainSession.updateUser
    }
}

function getSessionId(context) {
    return context.cookies.session.sessionCookie
}

function logout(context) {
    context.removeCookie("rememberCookie")
    context.removeCookie("session")
    context.sustainSession.setUser(defaultUser)
    context.sustainSession.startNewSession()
}
