import React from 'react';
import { Box, Step, Stepper, StepButton, Stack, StepContent, Button, Alert, FormControlLabel, Checkbox } from '@mui/material';
import useRegistrationValidation from '../hooks/accountModal/useRegistrationValidation';
import useAccountActions from '../hooks/useAccountActions';
import useInstitutionInfo from '../hooks/accountModal/useInstitutionInfo';
import useAboutUser from '../hooks/accountModal/useAboutUser';
// import ReCAPTCHA from 'react-google-recaptcha';
// import useRecaptcha from '../hooks/accountModal/useRecaptcha';
import useRegisterStepper from '../hooks/accountModal/useRegisterStepper';

export default function RegisterStepper({ handleClose }) {
    // const captchaContext = useRecaptcha();
    const accountActions = useAccountActions();
    const validation = useRegistrationValidation();
    const institution = useInstitutionInfo();
    const aboutuser = useAboutUser();
    const context = useRegisterStepper(/*captchaContext,*/ validation, institution, aboutuser);

    return (
        <Box>
            <Stepper nonLinear activeStep={context.activeStep} orientation="vertical" sx={{ margin: '0.5' }}>
                {context.steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepButton onClick={context.handleStep(index)}>{step.label}</StepButton>
                        <StepContent>
                            <Stack spacing={2}>
                                {step.body}
                                <Stack direction="row" spacing={2}>
                                    <Button disabled={index === 0} onClick={context.handleBack}>
                                        Back
                                    </Button>
                                    <Button variant="contained" onClick={context.handleNext} disabled={validation.shouldDisableAccountButton()}>
                                        {index === context.steps.length - 1 ? 'Finish' : 'Continue'}
                                    </Button>
                                </Stack>
                            </Stack>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            <br />
            <Stack alignItems="center">
                {context.activeStep === context.steps.length && (
                    <>
                        {context.userExists ? <Alert severity="error">A user with this email address exists</Alert> : null}
                        {/* <Captcha context={captchaContext} /> */}
                        <FormControlLabel control={<Checkbox checked={accountActions.rememberMe} />} label="Remember me" onChange={(event) => accountActions.setRememberMe(event.target.checked)} />
                        <Stack direction="row" spacing={4}>
                            <Button onClick={context.handleReset}>Reset</Button>
                            <RegisterButton context={{ context, validation, institution, aboutuser, accountActions, /*captchaContext,*/ handleClose }} />
                        </Stack>
                    </>
                )}
            </Stack>
            <br />
        </Box>
    );
}

// function Captcha({context}) {
//     return (
//         <form>
//             <ReCAPTCHA
//                 sitekey={process.env.REACT_APP_SITE_KEY}
//                 ref={context.captchaRef}
//                 style={{marginBottom:'1vh'}}
//                 onChange={context.handleSubmit}
//             />
//         </form>
//     );
// }

function RegisterButton({ context }) {
    return (
        <Button
            variant="contained"
            // disabled={!context.captchaContext.isCaptchaDone && process.env.NODE_ENV !== "development"}
            onClick={async () => {
                const values = context.validation.context;
                const exists = await context.accountActions.registerUser({
                    name: `${values.first} ${values.last}`,
                    email: values.email,
                    password: values.password,
                    institution: { ids: context.institution.context.instSelections, extraText: context.institution.context.instInfo },
                    description: { ids: context.aboutuser.context.yourselfSelections, extraText: context.aboutuser.context.otherYourself },
                    expertise: { ids: context.aboutuser.context.expertiseSelections, extraText: context.aboutuser.context.otherExpertise },
                    country: values.country,
                    rememberMe: context.accountActions.rememberMe
                });
                context.context.setUserExists(exists);
                if (!exists) context.handleClose();
            }}
        >
            Register
        </Button>
    );
}
